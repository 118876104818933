import React, {useEffect, useRef, useState} from 'react';
import LicenseService from "../../http-services/license.service";
import Alert from "../../components/Alert/Alert";
import CreateLicenseForm from "../../components/Forms/License/CreateLicenseForm";
import LicenseList from "../../components/License/LicenseList";

const License = (props) => {
    const [licenses, setLicenses] = useState([]);
    
    const [alert, setAlert] = useState({});
    const close = () =>{
        setAlert({...alert, open: false})
    }

    const getLicenses = () => {
        LicenseService.getLicenses()
            .then(response => {
                setLicenses(response.data);
                
            })
            .catch(error => {
                if (error.response) {
                    setAlert({ severity: 'error', message: error.response.data.message, open: true});
                    // alertRef.current.toggle('error', error.response.data.message);
                } else {
                    setAlert({ severity: 'error', message: error.toString(), open: true});
                    // alertRef.current.toggle('error', error.toString());
                }
                
            })
    }

    useEffect(() => getLicenses(), []);

    const createLicense = (values, form) => {
        console.log('the license values are', values);
        LicenseService.createLicense(values)
            .then(response => {
                getLicenses();
                
            })
            .catch(error => {
                if (error.response) {
                    setAlert({ severity: 'error', message: error.response.data.message, open: true});
                    // alertRef.current.toggle('error', error.response.data.message);
                }
                
            })
    }

    return (
        <div>
            <Alert alert={alert} close={close}/>
            <CreateLicenseForm onSubmit={createLicense}/>
            <LicenseList licenses={licenses}/>
        </div>
    );
}

export default License;
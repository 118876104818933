import http from './http-common';
class HygiaService {
    createHygia(data) {
        return http.post('/admin/hygia', data);
    }

    getHygiaById(id) {
        return http.get(`/admin/hygia/${id}`);
    }

    getHygiaByKey(id) {
        return http.get(`/admin/check/hygia/${id}`);
    }

    resetHygiaTokens(id) {
        return http.put(`/admin/hygia/${id}/reset-keys`);
    }

    updateHygia(id, data) {
        return http.put(`/admin/hygia/${id}`, data);
    }

    deleteHygia(id) {
        return http.delete(`/admin/hygia/${id}`);
    }

    downloadConso(id) {
        return http.get(`/admin/hygia/${id}/download-conso`);
    }
}

export default new HygiaService();
import http from './http-common';
class CounterService {
    getCounters() {
        return http.get('/counters');
    }

    deleteCounter(data) {
        return http.delete('/counter/delete', {data});
    }

    createCounter(data) {
        return http.post('/counter/create', data);
    }

    getCounter(id){
        return http.get('/counter/'+id )
    }

    updateCounter(data){
        return http.put('/counter/update',data);
    }
}

export default new CounterService();

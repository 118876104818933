import React, {useEffect, useState} from 'react';
import CounterService from "../http-services/counter.service";
import UserService from '../http-services/user.service';
import Alert from "../components/Alert/Alert";
import BackdropLoader from "../components/BackdropLoader/Backdrop";
import {i18n} from "../utils/i18n";
import {default as MaterialTable} from "../components/MaterialTable/MaterialTable";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";

const Counter = (props) => {
    const [counters, setCounters] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    
    const [alert, setAlert] = useState({});
    const close = () =>{
        setAlert({...alert, open: false})
    }

    const getCounters = () =>{
        CounterService.getCounters()
            .then(response => {
                setCounters(response.data);
            })
            .catch(error => {
                if (error.response) {
                    setAlert({ severity: 'error', message: error.response.data.message, open: true});
                } else {
                    setAlert({ severity: 'error', message: error.toString(), open: true});
                }
            });
    }

    const getData = () => {
        // load counters from DB
        setLoading(true);
        getCounters();
        setLoading(false);
    }

    useEffect(() => {getData();}, []);

    const languageOptions = (props) =>{
        return(
            <Select fullWidth onChange={e => props.onChange(e.target.value)} defaultValue={props.value} value={props.value}>
                <MenuItem value="fr-FR">Français</MenuItem>
                <MenuItem value="en-GB">Anglais</MenuItem>
                <MenuItem value="sv-SE">Suédois</MenuItem>
            </Select>
        )
    }

    const languages = { 
        'fr-FR': 'Français',
        'en-GB': 'Anglais',
        'sv-SE': 'Suédois'
    }

    const languageRenderAgent = (props) =>{
        if (props.agent_lang in languages){
            return languages[props.agent_lang];
        }
        return 'not chosen';
    }

    const languageRenderUser = (props) =>{        
        if (props.user_lang in languages){
            return languages[props.user_lang];
        }
        return 'not chosen';
    }

    const columns = [
        { field: 'title', title: i18n._("title"), minWidth: 120 },
        { field: 'agent_lang', title: "langue agent", minWidth: 120,render: languageRenderAgent, editComponent: languageOptions},
        { field: 'user_lang', title: "langue utilisateur", minWidth: 200, align: 'left',render: languageRenderUser, editComponent: languageOptions },
    ];

    const onRowClick = (e,rowData) =>{
        history.push(`/show-counter/${rowData._id}`)
    }
    return (
        <>
            <Alert alert={alert} close={close}/>
            <BackdropLoader open={loading}/>
            <MaterialTable
                onRowClick={onRowClick}
                columns={columns}
                data={counters}
                title="Guichet"
                editable={{
                    onRowAdd: (newData) => new Promise((resolve) => {
                        CounterService.createCounter(newData)
                            .then(() => {
                                getData(() => {
                                    setAlert({ severity: 'success', message: i18n._("Guichet Created") + ` - ${newData.email}` });
                                    
                                });
                            })
                            .catch(error => {
                                if (error.response) {
                                    setAlert({ severity: 'error', message:  error.response.data.message });
                                }
                            })
                            resolve();
                    }),
                    onRowDelete: (oldData) => new Promise((resolve) => {
                        CounterService.deleteCounter(oldData)
                            .then(response => {
                                getCounters(() => {
                                    setAlert({ severity: 'success', message:  i18n._("Guichet Deleted") });
                                });
                            })
                            .catch(error => {
                                if (error.response) {
                                    setAlert({ severity: 'error', message:  error.response.data.message });
                                }
                            })
                            resolve();
                    })
                }}
            />
        </>
    );
}

export default Counter;
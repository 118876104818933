import React, {useEffect, useRef, useState} from 'react';
import Alert from "../components/Alert/Alert";
import { useParams } from 'react-router-dom';
import UserService from '../http-services/user.service';
import LicenseService from '../http-services/license.service';
import CounterService from '../http-services/counter.service';
import BackdropLoader from "../components/BackdropLoader/Backdrop";
import {Form, Field} from 'react-final-form';
import {default as MaterialTable} from "../components/MaterialTable/MaterialTable";
import moment from 'moment';
import Box from "@material-ui/core/Box";
import {TextField, Select, MenuItem, FormControl, InputLabel, Typography, Grid} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { formatSeconds } from '../utils/common';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    mb_25: {
        marginBottom: '25px',
    },
    mb_50: {
        marginBottom: '50px',
    },
    btn:{
        margin: '15px 0',
        backgroundColor: '#cfdde4',
        "&:hover":{
            backgroundColor: '#7da0b0'
        }
    },
    btn_clipboard:{
        margin: '10px',
        backgroundColor: '#cfdde4',
        "&:hover":{
            backgroundColor: '#7da0b0'
        }
    },
    w_100:{
        width:'100%'
    }

});

const ShowCounter = (props) => {

    const [loading, setLoading] = useState(false);
    const [copySuccessAgent, setCopySuccessAgent] = useState('');
    const [copySuccessUser, setCopySuccessUser] = useState('');
    const [counter, setCounter] = useState(null);
    const tokenInputRefAgent = useRef(null);
    const tokenInputRefUser = useRef(null);
    const {id} = useParams();
    
    
    const [alert, setAlert] = useState({});
    const close = () =>{
        setAlert({...alert, open: false})
    }

    
    
    const onSubmit = async (values,form) => {
        setLoading(true);
        CounterService.updateCounter(values)
        .then(res=>{
            setAlert({ severity: 'success', message: "Guichet modifié", open: true });
            setCounter(values);
        })
        .catch(error => {
            if (error.response) {
                setAlert({ severity: 'error', message: error.response.data.message, open: true });
            }
            else{
                setAlert({ severity: 'error', message: error.toString() });
            }
        })
        setLoading(false);

    }

    useEffect( () =>{
        setLoading(true);
        
        Promise.all([CounterService.getCounter(id)])
        .then((values) =>{
            setCounter(values[0].data);
        })
        .catch((reason) =>{
            setAlert({ severity: 'error', message: reason, open: true });
        })
        setLoading(false);
    },[]);


    const renderLanguageItems = (title,props) =>{
        return (
            <FormControl fullWidth>
                <InputLabel id={"select-language_"+ props.name}>
                    {title}
                </InputLabel>
                <Select fullWidth id={"select-language_"+props.name} labelId={"select-language_"+props.name} name={props.input.name}  value={props.input.value} onChange={props.input.onChange}>
                <MenuItem value="fr-FR">Français</MenuItem>
                    <MenuItem value="en-GB">Anglais</MenuItem>
                    <MenuItem value="sv-SE">Suédois</MenuItem>
                    <MenuItem value="nl-NL">Neerlandais</MenuItem>
                    <MenuItem value="de-DE">Allemand </MenuItem>
                    <MenuItem value="es-ES">Espagnol</MenuItem>
                    <MenuItem value="it-IT">Italien</MenuItem>
                    <MenuItem value="pt-PT">Portugais</MenuItem>
                    <MenuItem value="tr-TR">Turc</MenuItem>
                    <MenuItem value="ar-SA">Arabe</MenuItem>
                    <MenuItem value="ro-RO">Roumain</MenuItem>
                    <MenuItem value="ru-RU">variant</MenuItem>
                    <MenuItem value="uk-UA">Ukrainien</MenuItem>
                </Select>
            </FormControl>
        )
           
    }

    function copyToClipboardAgent(e) {
        e.preventDefault();
        tokenInputRefAgent.current.select();
        document.execCommand('copy');
        setCopySuccessAgent('Copié');
        setTimeout(() => {
            setCopySuccessAgent('');
        }, 3000);
    };

    function copyToClipboardUser(e) {
        e.preventDefault();
        tokenInputRefUser.current.select();
        document.execCommand('copy');
        setCopySuccessUser('Copié');
        setTimeout(() => {
            setCopySuccessUser('');
        }, 3000);
    };

    const classes = useStyles();
    return (
        <div>
            <Alert alert={alert} close={close}/>
            <BackdropLoader open={loading}/>
            {<div>
                    <Typography variant="h5" gutterBottom>
                        Information du guichet
                    </Typography>
                    <Divider light /> 
                   <Form
                    onSubmit={onSubmit}
                    initialValues={counter}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit} className={classes.mb_50}>
                            <Box p={1}>
                                <Field name="title" placeholder={"titre guichet"} label={"titre guichet"}>
                                    {props => <TextField variant="outlined" fullWidth {...props} {...props.input}/>}
                                </Field>
                            </Box>
                            
                            <h5>
                                Informations agent et utilisateur
                            </h5>
                            <Divider light />
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box p={1} className={classes.mb_25}>
                                        <Field name="agent_lang" label={"Agent language"}>
                                            {props => 
                                                renderLanguageItems("Langue d'agent", props)
                                            }
                                        </Field>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box p={1} className={classes.mb_25}>
                                        <Field name="user_lang" label={"User language"}>
                                            {props => 
                                                renderLanguageItems("langue d'utilisateur", props)
                                            }
                                        </Field>
                                    </Box>
                                </Grid>

                                { counter && counter.agent_token &&
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <TextField
                                                className={classes.w_100}
                                                inputRef={tokenInputRefAgent}
                                                id="outlined-read-only-input"
                                                label="Token agent"
                                                defaultValue={counter.agent_token}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div>
                                                <Button className={`shadow-2 mb-4 ${classes.btn_clipboard}`} onClick={copyToClipboardAgent}>Copier le token</Button>
                                                {copySuccessAgent}    
                                            </div>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container><br/></Grid>
                                { counter && counter.user_token &&
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <TextField
                                                className={classes.w_100}
                                                inputRef={tokenInputRefUser}
                                                id="outlined-read-only-input"
                                                label="Token utilisateur"
                                                defaultValue={counter.user_token}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div>
                                                <Button className={`shadow-2 mb-4 ${classes.btn_clipboard}`} onClick={copyToClipboardUser}>Copier le token</Button>
                                                {copySuccessUser}    
                                            </div>
                                        </Grid>
                                    </Grid>
                                }


                            </Grid>
                           
                            <Button disabled={submitting ? "disabled" : ""} fullWidth type="submit" className={`shadow-2 mb-4 ${classes.btn}`}>Modifier guichet</Button>
                            {/* <Divider variant="middle"/> */}
                        </form>
                    )}>
                   </Form>
                </div>
            }
            
        </div>
    );
}

export default ShowCounter;